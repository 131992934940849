
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component({})
export default class SelectAddress extends Vue {
  @Prop({ type: Array, default: [] })
  private domainList!: Common.Domain.BaseInfoBackend[]

  @Prop({ type: String, default: '' })
  private value!: string

  private keywork = ''

  private inputfocus = false

  private get showdropDown () {
    return this.searchResult.length > 0 && this.inputfocus
  }

  private handleFocus () {
    this.inputfocus = true
  }

  private handleBlur () {
    setTimeout(() => {
      this.inputfocus = false
    }, 150)
  }

  private get searchResult (): Common.Domain.BaseInfoBackend[] {
    return this.domainList
  }

  private get width () {
    if (this.$siteConfig.isMobile) {
      return 'calc(100% - 104px)'
    }

    return '525px'
  }

  private handleSelect (address: string) {
    this.keywork = address
    this.$emit('input', address)
  }

  @Watch('keywork')
  private keyworkChange (newValue: string) {
    this.$emit('input', newValue)
  }

  @Watch('value', { immediate: true })
  private changeValue (newValue: string) {
    this.keywork = newValue
  }
}
