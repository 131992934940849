
import { Vue, Component, Prop } from 'vue-property-decorator'
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import { eventBus, EventBus } from '@/utils/event'
import { getName, setName, getNameHash } from '@/sdk/domain'
import { successMessage, errorMessage } from '@/utils/message'

@Component({})
export default class ReverseRecord extends Vue {
  @Prop({ type: Array, default: [] })
  private domainList!: Common.Domain.BaseInfoBackend[]

  private sendingTx = false

  private loading = false

  private processing = false

  private name = ''

  private get text () {
    return {
      title: lang('MY_NAME_REVERSE_RECORD'),
      notSet: lang('MY_NAME_NOT_SET'),
      loading: lang('DOMAIN_NAME_LOADING')
    }
  }

  private handleClick () {
    if (this.processing || this.loading) return
    this.$emit('showDialog', this.name)
  }

  private get isConnect () {
    return wallet.accountId !== ''
  }

  private async getName () {
    if (!this.isConnect) return
    this.loading = true
    const res = await getName(wallet.accountId)
    const namehash = res.toHexString()
    const matching = this.domainList.filter(item => getNameHash(item.name) === namehash)
    this.name = matching.length ? matching[0].name : ''
    this.loading = false
  }

  private async setReverseRecord (domain: string): Promise<void> {
    if (domain === this.name) return
    this.processing = true
    try {
      await setName(domain)
      successMessage(lang('REVERRECORD_SAVE_SUCCESS'))
      this.name = domain
      this.processing = false
    } catch (e) {
      errorMessage(e.message)
      this.processing = false
      throw e
    }
  }

  created () {
    this.getName()
    eventBus.on(EventBus.Types.REVERSE_RECORD_CONFIRM, this.setReverseRecord)
  }

  beforeDestroy () {
    eventBus.off(EventBus.Types.REVERSE_RECORD_CONFIRM, this.setReverseRecord)
  }
}
