
import { Component, Vue, Prop } from 'vue-property-decorator'
import { lang } from '@/lang'
import { wallet } from '@/utils/wallet'
import { eventBus, EventBus } from '@/utils/event'
import SelectAddress from './SelectAddress.vue'

@Component({
  components: {
    SelectAddress
  }
})
export default class ReverRecordDialog extends Vue {
  @Prop({ type: Array, default: [] })
  private domainList!: Common.Domain.BaseInfoBackend[]

  private show = false

  private selectedDomain = ''

  private get saveDisable (): boolean {
    const ifIncludes = this.domainList.filter(item => {
      return item.name === this.selectedDomain
    })

    return !ifIncludes.length
  }

  private get text () {
    return {
      title: lang('REVERRECORD_DIALOG_TITLE'),
      des_1: lang('REVERRECORD_DIALOG_DES_1'),
      des_2: lang('REVERRECORD_DIALOG_DES_2')
        .replace('#2', wallet.accountId)
        .replace('#1', this.domainList[0] ? this.domainList[0].name : ''),
      des_3: lang('REVERRECORD_DIALOG_DES_3'),
      buttonCancel: lang('REVERRECORD_DIALOG_CANCEL'),
      buttonConfirm: lang('REVERRECORD_DIALOG_SAVE')
    }
  }

  private get width () {
    if (this.$siteConfig.isMobile) {
      return 'calc(100% - 56px)'
    }

    return '573px'
  }

  open (currRecord: string) {
    this.show = true
    this.selectedDomain = currRecord
  }

  private handelCancel () {
    this.show = false
    this.selectedDomain = ''
  }

  handlerConfirm () {
    this.show = false
    console.log('selectedDomain', this.selectedDomain)
    eventBus.emit(EventBus.Types.REVERSE_RECORD_CONFIRM, this.selectedDomain)
  }
}
